// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var dialog = Cx.cx([
      "relative",
      "z-10"
    ]);

var dilalogBackdrop = Cx.cx([
      "fixed inset-0",
      "bg-blue-500/75",
      "transition-opacity",
      "data-[closed]:opacity-0",
      "data-[enter]:duration-300",
      "data-[leave]:duration-200",
      "data-[enter]:ease-out",
      "data-[leave]:ease-in"
    ]);

var container = Cx.cx(["fixed inset-0 z-10 w-screen overflow-y-auto"]);

var innerContainer = Cx.cx(["flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"]);

var dialogPanel = Cx.cx(["relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-sm sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"]);

var iconContainer = Cx.cx(["mx-auto flex size-24 items-center justify-center rounded-full bg-teal-100"]);

var messageContainer = Cx.cx(["mt-3 text-center sm:mt-5"]);

var dialogTitle = Cx.cx([
      "text-base",
      "font-semibold",
      "text-gray-900"
    ]);

var buttonContainer = Cx.cx(["flex justify-center mt-5 sm:mt-6"]);

var messageTextContainer = "mt-2";

var message = "text-sm text-gray-500";

export {
  dialog ,
  dilalogBackdrop ,
  container ,
  innerContainer ,
  dialogPanel ,
  iconContainer ,
  messageContainer ,
  dialogTitle ,
  messageTextContainer ,
  message ,
  buttonContainer ,
}
/* dialog Not a pure module */
