// extracted by mini-css-extract-plugin
export var column = "DashboardLocationsIndexOptions__column__xpt2e";
export var flex = "DashboardLocationsIndexOptions__flex__qZpcL";
export var flexColumn = "DashboardLocationsIndexOptions__flexColumn__gVaUT";
export var gap1 = "DashboardLocationsIndexOptions__gap1__t8iWI";
export var gap2 = "DashboardLocationsIndexOptions__gap2__VWcCJ";
export var gap3 = "DashboardLocationsIndexOptions__gap3__G1aNs";
export var gap4 = "DashboardLocationsIndexOptions__gap4__cUy6n";
export var gap5 = "DashboardLocationsIndexOptions__gap5__nSBHr";
export var popoverBody = "DashboardLocationsIndexOptions__popoverBody__z7YWm";
export var popoverBodyLink = "DashboardLocationsIndexOptions__popoverBodyLink__qDiNZ";
export var popoverBodyLinkIcon = "DashboardLocationsIndexOptions__popoverBodyLinkIcon__mRvCN";
export var row = "DashboardLocationsIndexOptions__row__soNcG";